

export function setKey(key, value) {
    if (localStorage) {
        if (value) {
            if (typeof value === 'object') {
                localStorage.setItem(key, JSON.stringify(value));
            } else {
                localStorage.setItem(key, value);
            }
        }
    }
}

export function getKey(key) {
    if (localStorage) {        
        return localStorage.getItem(key);
    }
    return null;
}

export function checkKey(key) {
    if (localStorage) {
        return localStorage.getItem(key);
    }
    return null;
}


// export async function clearKey() {
//     let keysNotDelete = [LOCALSTORAGE.NO_LANGUAGE_SELECTED, LOCALSTORAGE.PREFERRED_LANGUAGES, LOCALSTORAGE.DEVICE_ID, LOCALSTORAGE.ANONYMOUS_ID, LOCALSTORAGE.G_AUTH_TOKEN];
//     let keysNotDelete2 = [];

//     await keysNotDelete.map(async (val, i) => {
//         if (window.localStorage) {
//             keysNotDelete2.push({ [keysNotDelete[i]]: await JSON.parse(getKey(val)) })
//         }
//     })
//     if (window.localStorage) {
//         window.localStorage.clear();
//     }
//     keysNotDelete2.map((val, i) => {
//         let keysNotDelete3 = Object.keys(keysNotDelete2[i])[0];
//         return setKey(keysNotDelete3, val[keysNotDelete3]);
//     })
// }

export function deleteKey(key) {
    if (localStorage) {
        localStorage.removeItem(key);
    }
}

