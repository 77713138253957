export const REGEX = {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_])[A-Za-z\d!@#$%^&*()_]{8,}$/,
    PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    OTP: /^\d{6}$/,
    OTP_4: /^\d{4}$/,
    MOBILE_NUMBER: /^[0-9]{10}$/,
    FIRST_NAME: /^\S*$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    EMAIL_ID: (/^[a-z0-9]+([-._][a-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/),
    NAME: /^([a-zA-Z])|([a-zA-Z].[a-zA-Z])|([a-zA-Z].)/,
    USER_ID: /^([a-zA-Z0-9!_@$.]){6,26}$/,
    USER_NAME: /^([a-zA-Z])|([a-zA-Z].[a-zA-Z])|([a-zA-Z].)/,
    PASSWORD_REGISTRATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$_!@])[A-Za-z\d$@!_]{8,12}$/,
    DATE: /^((02?[1-9]|[12][0-9]|3[01])[- /.](02?[1-9]|1[012])[- /.](19|20)[0-9]{2})*$/,
    SPECIAL_CHARACTERS: /[^a-zA-Z0-9]/,
};

export const COMMON_TEXT = {
    PROCEED: 'Proceed',
    LOGIN: 'Login',
    UPDATE: 'Update',
    NOT_USER: 'Not on Tata Play? Get Now',
    T_AND_C: 'Terms and Conditions',
    SEND_OTP: 'Reset Password',
    RESEND_OTP: 'Resend OTP Code',
    AUTHENTICATE: 'Authenticate',
    CONFIRM: 'Confirm',
    LICENCE_AGREEMENT: 'Terms & Conditions',//change
    DATA_PRIVACY: 'Data Privacy',
    GET_OTP:"Get OTP",
    SUBMIT: 'Submit',
    LOGIN_TITLE:"Log in to Cignal Super to start watching",
    MODIFY_PLAN_TEXT:{
        TITLE:"Modify Your Plan",
        DESCRIPTION:"Oops, sorry you can't modify your subscription yet. We will make this available soon.",
    },
    
    PAYMENT_SUCCESSFULL:{
        PAYMENT_TITLE:"Payment Successful",
        PAYMENT_DESCRIPTION:"You have now subscribed to "
    },
    PAYMENT_UNSUCCESSFULL :{
        FAILED_TITLE:"Transaction Failed",
        FAILED_DESCRIPTION:"If your money was deducted, please wait for 3-5 working days before this will be refunded",
    },
     LOG_OUT:{
        TITLE:"Sure you want to log out ?",
        DESCRIPTION:"You will miss out on the latest content from your favourite apps."
     },
     PAYMENT_PROCESS:{
        TITLE:"Processing your Payment",
        DESCRIPTION:"Please don't refresh or press back"
     },
     NO_BAID_TEXT:{
        TITLE:"Login Error",
        DESCRIPTION:"Something went wrong. Please try after sometime",
    },
    CANCEL_PLAN:{
        TITLE:"Plan Cancellation Request Received",
        // DESCRIPTION:"",
    },
    PAYMENT_PENDING :{
        TITLE:"Transaction Pending",
        FAILED_DESCRIPTION:"",
    },
};
export const LENGTH_CHECK = {
    SID: 10,
    RMN: 10,
    OTP: 4,
    OTP_4: 4,
    PASSWORD: 8,
};

export const BROWSER_TYPE = {
    CHROME: "CHROME",
    IE: "MICROSOFT INTERNET EXPLORER",
    EDGE: "MICROSOFT EDGE",
    OPERA: "OPERA",
    FIREFOX: "FIREFOX",
    SAFARI: "SAFARI",
    UC: "UC",
};

export const APIPAYLOAD = {
    deviceId: '9150e608fedce41fVYT',
    anonymousId: "e5c333e3-86c1-4e7b-8e5b-5aa54b81f5cf",
    dtoken:"eKKA4f3xTKi29lSM_2-uoE:APA91bGDnYXVETR6NV6dKQ0Y4OV9hJgcknuRUcakRXg8MHvei4erNaA5Sr8iVcbFzlkVnMgxqyxOe-yK3W_nbotXP6zQUKXF8X_8ANkC275fWlGihzBsgPEF"
}


export const PLAY_STORE_URL = {
    ANDROID: 'https://play.google.com/store/apps',
    IOS: 'https://apps.apple.com/app',
};