import React, { useState } from 'react';
import PropTypes from "prop-types";
// import loaderImage from "@assets/images/loader.gif";

import './style.scss';
import LoaderNewImge from '../../assets/loader.svg'

const Loader = (props) => {
    //    console.log(LoaderNew,"checkState");
     const [alwaysVisible,setAlwaysVisible] = useState(true)
     let { showContainer = true, alwaysVisibles = true } = props;
    // const isLoading = useSelector(state => state.commonContent.isLoading);
    // const isPaginationLoaderVisible=useSelector((state)=>{
    //     return state.commonContent.isPaginationLoaderVisible;
    // })
    // const fromLogin = useSelector(state => state.commonContent.fromLogin);
    //  if((!isLoading || isPaginationLoaderVisible ) && (!alwaysVisible && !fromLogin)){
    //     return null;
    // }
    if(props?.type!=="paymentsuccess"){
        setTimeout(() => {
            setAlwaysVisible(false);
            // console.log("abcd--",alwaysVisible);
        }, 4000);
    }

    return (
        <>
            {alwaysVisible ? <div className={`${showContainer && `loader-container`}`}>
                <img className={'scroll-loader'}
                    src={LoaderNewImge}
                    alt="img" />
            </div> : null
            }
        </>


    )
};

Loader.propTypes = {
    showContainer: PropTypes.bool,
    alwaysVisible: PropTypes.bool,
};
export default React.memo(Loader);
