import { useState } from "react";
import { LOCALSTORAGE } from "../Constants/Constants";
import { getKey, setKey } from "./storage";
import { createPortal } from "react-dom";
import { APIPAYLOAD, PLAY_STORE_URL } from "./Constants";

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    otherMobileDevice: function () {
        return (
            navigator.userAgent.match(/Mobi/i) &&
            navigator.platform !== "iPad" &&
            !navigator.userAgent.match(/iPad/i)
        );
    },
    any: function () {
        return (
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows() ||
            isMobile.otherMobileDevice()
        );
    },
};

export const isMobileBrowser = () => {
    return !!isMobile.any();
};


export const redirectToApp = () => {
    let envUrl = `https://cignal-super-uat.com/`;
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        setTimeout(function () {
            //
            if (window.confirm('Safari cannot open this page because address is invalid')) {
                // eslint-disable-next-line no-restricted-globals
                // if (new Date().valueOf() - now > 3000) return;
                window.location.href = PLAY_STORE_URL.IOS;
            }
        }, 2000);
        window.location.href = "https://cignal-super-uat.com/";
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        setTimeout(function () {
            window.location.replace(PLAY_STORE_URL.ANDROID);
        }, 2000);
        window.location = "https://cignal-super-uat.com/";//cignal-super-uat.com
    } else {
        setTimeout(function () {
            window.location.href = PLAY_STORE_URL.ANDROID;
        }, 2000);
        window.open(`${envUrl}`, "_blank");
    }
};

export const redirectToAppStore = () => {
    //isMobile.any() ? redirectToAppStore()
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location.href = PLAY_STORE_URL.IOS;
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = `https://play.app.goo.gl/?link=${PLAY_STORE_URL.ANDROID}`;
    } else {
        // in iPhone this condition is running might be due to navigator.userAgent that is why used IOS constant in else
        window.location.href = PLAY_STORE_URL.IOS;
    }
};

export const removeClass = (element, ...classNames) => {
    classNames.forEach((className) => {
        element && element.classList !== undefined
            ? element.classList.contains(className) &&
            element.classList.remove(className)
            : console.log("Element not Found! Cannot remove " + className + " class");
    });
};

export const addClass = (element, ...classNames) => {
    classNames.forEach((className) => {
        element
            ? !element.classList.contains(className) &&
            element.classList.add(className)
            : console.log("Element not Found! Cannot add " + className + " class");
    });
};

export const splitSentenceByHash=(sentence)=>{
    let resultArray = sentence && sentence.includes('#') ? sentence.split('#') : [sentence];    
    return resultArray;
}

export const  toCamelCase =(arr) => {
    return arr && arr.map((word, index) => {
      if (index === 0) {
        // First word in lowercase
        return word[0].toUpperCase() + word.slice(1).toLowerCase();;
      } else {
        // Capitalize the first letter of the subsequent words
        return word.charAt(0).toUpperCase() + word.slice(1).toUpperCase();
      }
    }).join(' '); // Join all the words
    // return arr.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join();
  }

  export const getParamsAPICall = () => {    
    const subs_user_info = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_USER_INFO))
    //||JSON.parse(localStorage.getItem("subs_user_info"));
    const token = JSON.parse(getKey(LOCALSTORAGE.VALID_OTP_DETAILS)) || JSON.parse(localStorage.getItem("validOtpDetails")) ;
    const userAuthorization = token && token.userAuthenticateToken;
    const deviceAuthenticateToken = token && token.deviceAuthenticateToken;
    const anonymousId =getKey(LOCALSTORAGE.ANONYMOUS_ID);
    const deviceId =getDeviceId()//APIPAYLOAD.deviceId //getKey(LOCALSTORAGE.ANONYMOUS_ID);
    const gAuthToken = getKey(LOCALSTORAGE.G_AUTH_TOKEN);

    // if(subs_user_info){
        const {subscriberId="",dthStatus,mobileNumber,accountStatus,bingeSubscriberId} = subs_user_info ? subs_user_info[0] || subs_user_info : {};
        const baId3= subs_user_info && subs_user_info[0]?.accountDetailsDTOList && subs_user_info[0]?.accountDetailsDTOList.length>0 ? subs_user_info[0]?.accountDetailsDTOList[0].baId : "";

        const baId2= subs_user_info ?  subs_user_info[0]?.baId : "";
        let baId =  baId2?baId2:baId3;
        return {userAuthorization, deviceAuthenticateToken,baId, subscriberId,dthStatus,mobileNumber,accountStatus,bingeSubscriberId,anonymousId,deviceId,gAuthToken};
      
        
    // }else{
    //     return {userAuthorization, deviceAuthenticateToken,anonymousId,deviceId,gAuthToken};

    // }
  

};
export const findProductById =(products,productId)=>{
     return [ products.find(product => product.productId === productId)];

}


export const getComponentList = (item) => {
    let componentList = item?.componentList?.find(packItem => packItem?.componentId === item?.productId);
    if (!componentList) {
        componentList = item?.componentList && item.componentList[0]
    }
    return componentList || [];
};

export const getDeviceId = () => {
    if (!getKey(LOCALSTORAGE.DEVICE_ID)) {
        let deviceId = new Date().getTime();
        setKey(LOCALSTORAGE.DEVICE_ID, deviceId);
        return deviceId;
    } else {
        return getKey(LOCALSTORAGE.DEVICE_ID);
    }
};
