import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { serviceConstants } from '../utils/ApiService';
import { ENDPOINT_URL, LOCALSTORAGE } from '../Constants/Constants';
import { getKey, setKey } from '../utils/storage';
import { APIPAYLOAD } from '../utils/Constants';
import { getDeviceId, getParamsAPICall } from '../utils/Common';


// Create a Context for both users and posts
export const DataContext = React.createContext();

// Create a Provider component
export const DataProvider = ({ children }) => {
    const [posts, setData] = useState([]);
    const [verifyPosts, setverifyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState()
    const [userSubscInfo, setUserSubscInfo] = useState()
    const [profileInfo, setProfileInfo] = useState([])
    const [currentSubs, setCurrentSubs] = useState()
    const [existingUserData, setExistingUserData] = useState()


    console.log("-------------", process.env.REACT_APP_API_URL, "------------", process.env + "---------------");

    const postData = async (endpoint, bodyData, urltype) => {
        let subsinfo;
        if (urltype && urltype !== "generateotp") {
            // if(urltype !== "validateotp"){

            // subsinfo = getParamsAPICall() 
            // }
            subsinfo = getParamsAPICall()
        }

        setLoading(true);
        setError(null);

        try {
            if (urltype === "generateotp") {
                const response = await axios.request(serviceConstants.rmnGetOtp(bodyData.mobileNumber));
                setData(response.data); // Handle the API response data
            } else if (urltype === "validateotp") {
                const response = await axios.request(serviceConstants.verifyRmnOpt(bodyData.mobileNumber, bodyData.otp, subsinfo.anonymousId));
                setverifyData(response?.data)
                setKey(LOCALSTORAGE.VALID_OTP_DETAILS, response?.data?.data)
                if (response?.data?.code === 0) {
                    // update exist user need to call here on response success --run details API 
                    getSubsDetails(bodyData.mobileNumber, response?.data?.data?.deviceAuthenticateToken, response?.data?.data?.userAuthenticateToken, subsinfo.anonymousId, subsinfo.deviceId)
                }
            }
        } catch (err) {
            setError(err.message); // Handle error
        } finally {
            setLoading(false); // Stop loading after completion
        }
    };

    const getUserInfo = async () => {

        // ////------------------------
        try {
            const response = await axios.request(serviceConstants.fetchConfig())
            if (response.status === 200) {
                setUserInfo(response?.data?.data?.config)
            } else {
                setUserInfo()
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false); // Stop loading after completion
        }
        //--------------------------------
    }

    const getSubsDetails = async (mobileNumber, deviceAuthenticateToken, userAuthenticateToken, anonymousId, deviceId) => {
        try {

            const responseSubsc = await axios.request(serviceConstants.subscriberDetails(mobileNumber, deviceAuthenticateToken, userAuthenticateToken, deviceId, anonymousId));

            if (responseSubsc?.data?.code === 0 && responseSubsc?.data?.data.length > 0) {
                console.log("abcd-getSubsDetails-1-", responseSubsc?.data?.data);

                if (responseSubsc?.data?.data[0].subscriberId && responseSubsc?.data?.data[0]?.baId) {
                    setUserSubscInfo(responseSubsc?.data?.data)
                    setKey(LOCALSTORAGE.SUBSCRIPTION_USER_INFO, responseSubsc?.data?.data)
                    // getCurrentSubscription()
                    existingUser()
                }
                if (responseSubsc?.data?.data[0].subscriberId && !responseSubsc?.data?.data[0]?.baId) {
                    createUser(mobileNumber, mobileNumber, deviceAuthenticateToken, userAuthenticateToken, "")
                }

            } else {
                setUserSubscInfo([])
                setCurrentSubs([])

            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false); // Stop loading after completion
        }
    }



    //get profile details
    const getProfile = async (mobileNumber, deviceAuthenticateToken, userAuthenticateToken, baid) => {
        try {
            const response = await axios.request(serviceConstants.getProfileDetails(mobileNumber, deviceAuthenticateToken, userAuthenticateToken, baid));
            if (response?.data?.code === 0) {
                setProfileInfo(response?.data?.data)
            } else {
                setProfileInfo([])

            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false); // Stop loading after completion
        }
    }

    const getCurrentSubscription = async (mobileNumber, deviceAuthenticateToken, userAuthenticateToken) => {
        let subsinfo = getParamsAPICall()
        setCurrentSubs([])
        try {
            let param = {
                "accountId": subsinfo.mobileNumber,
                "baId": subsinfo.baId,
                "dthStatus": subsinfo.dthStatus
            }
            const response = await axios.request(serviceConstants.getCurrentSubscription(subsinfo.mobileNumber, subsinfo.deviceAuthenticateToken, subsinfo.userAuthorization, param, subsinfo.anonymousId, subsinfo.deviceId));
            if (response?.data?.code === 0 && response?.data?.data) {
                localStorage.setItem("ssoLogin", true);//to run current subs API
                setCurrentSubs(response?.data?.data)
            } else {
                setCurrentSubs([])
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false); // Stop loading after completion
        }
    }

    useEffect(() => {
        const ssoLogin = localStorage.getItem("ssoLogin")
        if (ssoLogin) {
            getCurrentSubscription()
        }
    }, [localStorage.getItem("ssoLogin")])


    const existingUser = async () => {
        let subsinfo = getParamsAPICall()
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-authenticated-userid", subsinfo?.subscriberId);
        myHeaders.append("deviceName", "WEB");
        myHeaders.append("platform", "WEB");
        myHeaders.append("anonymousId",  subsinfo?.anonymousId);
        myHeaders.append("Authorization", subsinfo?.userAuthorization);
        myHeaders.append("deviceId", subsinfo?.deviceId);
        myHeaders.append("deviceToken", subsinfo?.deviceAuthenticateToken);
        myHeaders.append("deviceType", "WEB");

        const raw = JSON.stringify({
            "login": "OTP",
            "mobileNumber": subsinfo?.mobileNumber,
            "bingeSubscriberId": subsinfo?.bingeSubscriberId,
            "subscriberId": subsinfo?.subscriberId,
            "baId": subsinfo?.baId,
            "dthStatus": subsinfo?.dthStatus
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_API_URL}${ENDPOINT_URL.EXISTING_USER}`, requestOptions)
        .then((response) => response.json())
            .then((response) => {
                console.log("abcd--ExistingUserInfo==",response);
                
                if(response?.code===0){
                    setKey(LOCALSTORAGE.EXISTING_USER_INFO, response)
                    setExistingUserData(response)
                    getCurrentSubscription()
                }else{
                    setExistingUserData(response)
                }

            })
    }

    const createUser = async (sid, mobilenumber) => {
        let subsinfo = getParamsAPICall()

        const myHeaders = new Headers();
        myHeaders.append("anonymousid", subsinfo.anonymousId);
        myHeaders.append("devicetoken", subsinfo.deviceAuthenticateToken);//"d5K355K1QM6J61uMUzQPQ4:APA91bEOQbpUl0JPvJGYQm-a1Wvq2UOYP8ToTSq0_7RKkXienrahlZHt-_8rt8VZG10Q4s-IC7wdW2FuQLyXhVbFUZ3Djq2W61od39Je22ScnDvUT6wbtbs");
        myHeaders.append("authorization", subsinfo.userAuthorization);//"EClMfPvr7Rree5pTFunSZVwZLmXGMAqeyIdWHi7Ia+U=.eyJpZCI6MSwibmFtZSI6IkFrcml0aSBUeWFnaSIsImlzRGVsZXRlZCI6ZmFsc2UsImVtYWlsIjoiYWtyaXRpLnR5YWdpQGluZmluaXRlLmNvbSIsImV4cGlyZXMiOjE3MjYyMTc5OTM2MDQsInJvbGUiOlt7ImlkIjoxLCJhdXRob3JpdHkiOiJST0xFX1NVUEVSX0FETUlOIn1dfQ==");
        myHeaders.append("content-type", "application/json");
        myHeaders.append("device", "WEB");
        myHeaders.append("deviceid", subsinfo.deviceId);
        myHeaders.append("devicename", "Web");
        myHeaders.append("platform", "WEB");

        const raw = JSON.stringify({
            "dthStatus": "Non DTH User",
            "subscriberId": sid,
            "login": "OTP",
            "mobileNumber": mobilenumber,
            "isPastBingeUser": false,
            "eulaChecked": true,
            "packageId": ""
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_API_URL}${ENDPOINT_URL.NEW_USER}`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                setKey(LOCALSTORAGE.SUBSCRIPTION_USER_INFO, response?.data)
                getCurrentSubscription()
            })
            .catch((error) => console.error(error));
    }

    const register = async () => {
        const myHeaders = new Headers();
        myHeaders.append("deviceId", getDeviceId());
        myHeaders.append("deviceType", "WEB");
        myHeaders.append("platform", "BINGE_ANYWHERE");

        const raw = "{}";

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_API_URL}${ENDPOINT_URL.REGISTER}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setKey(LOCALSTORAGE.ANONYMOUS_ID, result?.data?.anonymousId);
                setKey(LOCALSTORAGE.G_AUTH_TOKEN, result?.data?.gAuthToken);
            })
            .catch((error) => console.error(error));
    }
    useEffect(() => {

        getUserInfo()
        register()

    }, []);

    return (
        <DataContext.Provider value={{ posts, verifyPosts, userInfo, userSubscInfo, profileInfo, currentSubs, loading, error, existingUserData, postData, getSubsDetails, getProfile, getCurrentSubscription }}>
            {children}
        </DataContext.Provider>
    );
};
