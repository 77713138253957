const apiVersion = 'v1';
const apiVersion2 = 'v2';
const apiVersion3 = 'v3';
const apiVersion5 = 'v5';
const apiVersion6 = 'v6';
export const ENDPOINT_URL ={
    GENERATE_OTP_RMN: `binge-mobile-services/oauth2/token`,//`binge-mobile-services/pub/api/${apiVersion}/user/authentication/generateOTP`
    VERIFY_OPT_RMN: `binge-mobile-services/pub/api/${apiVersion}/user/authentication/validateOTP`,
    GET_PACK_LIST: `manage-app-read/v1/BINGE_OTT/CYOP/getPacks`,
    PACK_LISTING_URL_LOGIN: `binge-mobile-services/api/${apiVersion}/subscription/packs`,
    PACK_LISTING_URL_WITHOUT_LOGIN:`/binge-mobile-services/pub/api/${apiVersion3}/subscription/packs`,
    CONFIG_URL:`binge-mobile-config/pub/${apiVersion}/api/config/binge/mobile`,
    PAYMENT_STATUS:`binge-mobile-services/api/${apiVersion}/payment/status`,
    SUBSCRIBER_DETAILS:`binge-mobile-services/api/${apiVersion3}/subscriber/details`,
    CURRENT_SUBSCRIBTION_DETAILS:`binge-mobile-services/api/${apiVersion2}/subscription/current`,
    PROOFILE_DETAILS:`binge-mobile-services/api/${apiVersion3}/subscriber/fetch/profile`,
    LOGOUT:`binge-mobile-services/api/${apiVersion2}/logout`,
    NEW_USER:`binge-mobile-services/api/${apiVersion3}/create/new/user`,
    REGISTER:`binge-mobile-services/pub/api/${apiVersion}/user/guest/register`,
    EXISTING_USER:`binge-mobile-services/api/${apiVersion3}/update/exist/user`,
    CANCEL_SUBSCRIPTION: `binge-mobile-services/api/${apiVersion6}/cancel/pack`,

    newOtpFlow: '4DOTP', 
    platform: 'BINGE_ANYWHERE',
    deviceId: '1720088976953', 
}

export const noop = () => { };

export const BROWSER_TYPE = {
    CHROME: "CHROME",
    IE: "MICROSOFT INTERNET EXPLORER",
    EDGE: "MICROSOFT EDGE",
    OPERA: "OPERA",
    FIREFOX: "FIREFOX",
    SAFARI: "SAFARI",
    UC: "UC",
};
export const OS = {
    WINDOWS: "WINDOWS",
    LINUX: "LINUX",
    IOS: "IOS", // check mac or ios
    VERSION: "VERSION",
};
export const LOCALSTORAGE = {
    USER_INFO: "userInfo",
    PACK_SELECTED: "packSelected",
    SUBSCRIPTION_TYPE: "subscriptionType",
    JWT_TOKEN: "jwtToken",
    LOGIN_DETAILS: "loginDetails",
    ANONYMOUS_ID: "anonymousId",
    TRANSACTION_ID: 'transactionID',
    PAYMENT_STATUS_VERBIAGE: 'paymentStatusVerbiage',
    PAYMENT_ERROR_STATUS_VERBIAGE: 'paymentErrorStatusVerbiage',
    SUBSCRIPTION_CHANGE_TYPE: 'subscriptionChangeType',
    PREVIOUS_SUBSCRIPTION_DETAILS: "modifySubscriptionFlow",
    IS_PAYMENT_FROM_SUBSCRIPTION: 'isPaymentFromSubscription',
    G_AUTH_TOKEN:"gAuthToken",
    GET_PAYMENT_DETAILS:"getpaymentDetails",
    VALID_OTP_DETAILS: "validOtpDetails",//--used
    SUBSCRIPTION_USER_INFO: "subsUserInfo",//--used
    PRODUCTID:"productid",
    RMN:"rmn",
    EXISTING_USER_INFO: "ExistingUserInfo",
    DEVICE_ID:"deviceId"

}

export const HEADER_CONSTANTS = {   
    BINGE_WEB_SMALL: 'BINGE_WEB_SMALL',
    BINGE_ANYWHERE: 'BINGE_ANYWHERE',
    WEB: 'WEB',
};
export const LENGTH_CHECK = {
    SID: 10,
    RMN: 10,
    OTP: 6,
    OTP_4: 4,
    PASSWORD: 8,
};
