import { toast, ToastContainer } from 'react-toastify';
import './App.css';
import SplashScreen from './Common/Splash';
import { DataProvider } from './Context/DataProvider';
import { PurchaseProvider } from './Context/PurchaseProvider';
import CignalRoute from './utils/route';
import { isMobile } from './utils/Common';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './Auth/AuthContext';
function App() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     const params = new URLSearchParams(window.location.search);
//     console.log("statu id",params.get('status'),params.get('merchantRefCode'));
//     if(params.get('merchantRefCode')){
//       let obj = {
//         pstatus:params.get('status'),
//         merchantRefCode:params.get('merchantRefCode')
//       }
//       navigate('/success',{ state: { paymentStatus: obj} }) 
//     }
// },[]);
  return (
    // <>
          <div className="App" >
            {/* <div> */}
            {/* <AuthProvider> */}
              <CignalRoute/>
              {/* </AuthProvider> */}
              {/* <SplashScreen /> */}
            {/* </div> */}
            {/* <ToastContainer
                autoClose={4000}
                hideProgressBar={true}
              /> */}
          </div>
          // <div>
          // <ToastContainer
          //       autoClose={4000}
          //       hideProgressBar={true}
          //     />
          // </div>
    // {/* </> */}
  );
}

export default App;
