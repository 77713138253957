// AuthContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        JSON.parse(localStorage.getItem("isAuthenticated")) || false
      );
      
      const login = () => {        
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", true);
      };
      
      const logout = () => {
        // event.preventDefault();
        setIsAuthenticated(false);
        localStorage.clear();
        // localStorage.setItem("isAuthenticated", false);
      };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
