import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { serviceConstants } from "../utils/ApiService";
import { getDeviceId, getParamsAPICall } from "../utils/Common";
import { ENDPOINT_URL } from "../Constants/Constants";
import { DataContext } from "./DataProvider";

export const PurchaseContext = createContext();

export const PurchaseProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [packList, setpackList] = useState([])
    const [paymentStatus, setPaymentStatus] = useState()
    const [summaryList, setSummaryList] = useState([])
    const [logoutData, setLogoutData] = useState()
    const { getCurrentSubscription } = useContext(DataContext);
    const [cancelPlanData, setCancelPlanData] = useState()

    console.log("-------------", process.env.REACT_APP_API_URL, "------------", process.env + "---------------");

    const postPcakData = async (urltype) => {
        setLoading(true);
        setError(null);
        try {

            // if (urltype === "choosepack") {
            const response = await axios.request(serviceConstants.packListingDataMobile());
            if (response?.data?.code === 0) {
                setpackList(response?.data?.data); // Handle the API response data
            }
        } catch (err) {
            setError(err.message); // Handle error
        } finally {
            setLoading(false); // Stop loading after completion
        }
    };

    const PayamentStatus = async (baid, paymentTransaction) => {
        try {
            let subsinfo = getParamsAPICall()
            let data = {
                "baId": subsinfo?.baId,
                "paymentTransaction": paymentTransaction,
                "cartId": ""
            }
            const response = await axios.request(serviceConstants.getPaymentResponse(data));
            if (response?.data?.code === 0) {
                setPaymentStatus(response?.data?.data); // Handle the API response data
                if (response?.data?.data?.paymentStatus == "SUCCESS") {
                    getCurrentSubscription()
                }
            } else {
                setPaymentStatus([]);
            }

        } catch (err) {
            setError(err.message); // Handle error
        } finally {
            setLoading(false); // Stop loading after completion
        }
    }

    const LogoutApp = async () => {
        try {
            console.log("abcd------>>>>>logout",);
            let subsinfo = getParamsAPICall()
            let url = `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.LOGOUT}/${subsinfo?.baId}`
            const myHeaders = new Headers();
            myHeaders.append("deviceId", subsinfo?.deviceId);
            myHeaders.append("deviceType", "WEB");
            myHeaders.append("deviceName", "WEB");
            myHeaders.append("anonymousId", subsinfo?.anonymousId);
            // myHeaders.append("profileId", "VR_0251f1d1543948c8a02bc531d5fe2716");
            myHeaders.append("authorization", `bearer ${subsinfo?.userAuthorization}`);
            myHeaders.append("dthStatus", subsinfo?.dthStatus);
            myHeaders.append("subscriptionType", "FREEMIUM");
            myHeaders.append("platform", "BINGE_ANYWHERE");
            myHeaders.append("x-authenticated-userid", subsinfo?.subscriberId);
            myHeaders.append("deviceToken", subsinfo?.deviceAuthenticateToken);
            myHeaders.append("subscriberId", subsinfo?.subscriberId);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append(url, "");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };

            fetch(url, requestOptions)
                .then((responsess) => responsess.json())
                .then((response) => {

                    console.log("abcd---", response);

                    // .catch((error) => console.error(error));
                    //     const response = await axios.request(serviceConstants.LogoutAPP());
                    if (response?.code === 0) {
                        let obj = {
                            value: true,
                            message: response?.message
                        }
                        setLogoutData(obj)
                    } else {
                        let obj = {
                            value: false,
                            message: response?.message
                        }
                        setLogoutData(obj)
                    }
                }).catch((err) => {
                    setError(err.message); // Handle error
                    let obj = {
                        value: false,
                        message: err.message
                    }
                    setLogoutData(obj)
                });

        } catch (err) {
            setError(err.message); // Handle error
            let obj = {
                value: false,
                message: err.message
            }
            setLogoutData(obj)
        } finally {
            setLoading(false); // Stop loading after completion
        }
    }

   const cancelPlan = () => {
        let subsinfo = getParamsAPICall()
        let url = `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.CANCEL_SUBSCRIPTION}/${subsinfo?.baId}`
        const myHeaders = new Headers();
        myHeaders.append("bingeSubscriberId", subsinfo?.bingeSubscriberId);
        myHeaders.append("rule", "BA");
        myHeaders.append("locale", "GBR");
        myHeaders.append("deviceId", getDeviceId());
        myHeaders.append("deviceType", "Web");
        myHeaders.append("deviceName", "Samsung SM-G988B");
        myHeaders.append("anonymousId", subsinfo?.anonymousId);
        // myHeaders.append("profileId", "VR_45ce7254957a42248ef158cbc904af87");
        myHeaders.append("authorization", `bearer ${subsinfo?.userAuthorization}`);
        myHeaders.append("dthStatus", subsinfo?.dthStatus);
        myHeaders.append("subscriptionType", "FREEMIUM");
        myHeaders.append("platform", "BINGE_ANYWHERE");
        myHeaders.append("x-authenticated-userid", subsinfo?.subscriberId);
        myHeaders.append("deviceToken", subsinfo?.deviceAuthenticateToken);
        myHeaders.append("subscriberId", subsinfo?.subscriberId);
        myHeaders.append("sourceApp", "uat");
        myHeaders.append("Cache-Control", "no-cache");
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append(url, "");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 0) {
                    setCancelPlanData(result)
                    getCurrentSubscription()
                } else {
                    setCancelPlanData(result)
                }
            })
            .catch((error) => console.error(error));
    }







    // useEffect(() => {
    // const fetchData = async () => {
    //     const body = {
    //         sessionid: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI1MDAwMjgyMzY4MTcyOTgzOTM0NjY5NCIsImV4cCI6MTcyOTg0MTE0OSwidG9rZW5VdWlkIjoiNTAwMDI4MjM2ODE3Mjk4MzkzNDY2OTQiLCJjb212aXZhQWNjb3VudElkIjoiNTAwMDI4MjM2OCIsImFub255bW91c0lkIjoiIiwic3Vic2NyaWJlcklkIjoiIiwibWl4cGFuZWxJZCI6IjJlMWViNjJhLWJiMTgtNDZiNS05YjBjLTMxZTRiOGRhYTQwZSIsInJtbiI6IjY1ODI0NDcwODQiLCJhZ2VudFR5cGUiOiIiLCJ1c2VyVHlwZSI6Ik5vbiBEVEggVXNlciIsInBsYXRmb3JtIjoiV0VCIiwiZGV2aWNlSWQiOiIxNzI4NjI1ODUyNjExIiwiYW5hbHl0aWNTb3VyY2UiOiJIb21lLVN1YnNjcmliZS1DVEEiLCJyZXR1cm5VcmwiOiJodHRwczovL3N0YWdpbmctd2ViLWZybS52aWRlb3JlYWR5LnR2Lz90aWNrVGljaz10cnVlIiwiYmluZ2VBY2NvdW50SWQiOiI1MDAwMjAxMTgzIiwic291cmNlIjoiQklOR0VfT1RUIiwiam91cm5leVNvdXJjZSI6IkRSQVdFUiNDWU9QIiwiam91cm5leVNvdXJjZVJlZmxkIjoiIiwidXRjVGltZXN0YW1wIjoiMTcyOTgzOTM0NjY5NCIsImFwcHNGbHllciI6IntcImFwcHNmbHllcklkXCI6XCJjMjU5OGFmYi1lMDM4LTRkNTgtYTQ0YS01YzE1YWM1OTViMDMtcFwiLFwiYWR2ZXJ0aXNpbmdJZFwiOlwiNTAwMDI4MjM2OFwiLFwiY3VzdG9tZXJVc2VySWRcIjpcIm51bGxcIixcImFwcFZlcnNpb25OYW1lXCI6XCJcIixcIm9zXCI6XCJcIixcImlwXCI6XCJudWxsXCIsXCJldmVudEN1cnJlbmN5XCI6XCJudWxsXCJcImRldmljZVwiOlwiV0VCXCJ9IiwiYmluZ2VBbnl3aGVyZSI6ZmFsc2UsIm9yaWdpbiI6IkRSQVdFUiNDWU9QIiwiYXBwbGllZENvdXBvbkRldGFpbHMiOiIiLCJ0b2tlblR5cGUiOiJzZXNzaW9uSWQifQ.SnhcVef7xqAAdFGFWLO7ZxIzoszFzQ6gLjTeOvUmio3r2k5oTVOyEfamnLAGQJBcCdHsW-Gx60X5qYubltUIhA"
    //     }
    //     setLoading(true);
    //     await Promise.all([postPcakData("", body, "choosepack")]); // Call  APIs concurrently
    //     setLoading(false);
    // };
    // fetchData();
    //     postPcakData("choosepack")
    // }, []);


    return (
        <PurchaseContext.Provider value={{ packList, summaryList, paymentStatus, error, loading, logoutData,cancelPlanData, postPcakData, PayamentStatus, LogoutApp,cancelPlan }}>
            {children}
        </PurchaseContext.Provider>
    )

}