
import { ENDPOINT_URL, HEADER_CONSTANTS, LOCALSTORAGE } from "../../src/Constants/Constants.js";
import { getParamsAPICall, isMobile } from "./Common.js";
import { APIPAYLOAD } from "./Constants.js";
import { getKey } from "./storage.js";
export const serviceConstants = {
    // **************** who have started from here***********
    rmnGetOtp: (rmn) => {
        return {
            'method': 'GET',
            url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.GENERATE_OTP_RMN}`,
            'headers': {
                'mobileNumber': rmn,
                'newOtpFlow': '4DOTP',
                'deviceId': APIPAYLOAD.deviceId,
                'anonymousId': APIPAYLOAD.anonymousId,
                'platform': 'BINGE_ANYWHERE'
            }

        };
    },

    verifyRmnOpt: (mobileNumber, otp,anonymousId,deviceId) => {
        const data = { mobileNumber, otp };
        return {
            url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.VERIFY_OPT_RMN}`,
            // url:`https://uat-tb.tapi.videoready.tv/${ENDPOINT_URL.VERIFY_OPT_RMN}`,
            method: "POST",
            headers: {
                'deviceId': deviceId,
                'anonymousId': anonymousId,
                'platform': 'BINGE_ANYWHERE',
                'Content-Type':'application/json',
                'deviceType': 'WEB',
                'deviceName': 'Web',
                'newOTPFlow': '4DOTP'

            },
            data: JSON.stringify(data),
            isAPIPrimary: true,
        };
    },

    // packListingData: (ssesionid) => {
    //      return {
    //     //     url: `${getBaseUrl()}${url.PACK_LISTING_URL}${baId}`,
    //         url: `https://uatmanageapps.tataplaybinge.com/${ENDPOINT_URL.GET_PACK_LIST}`,
    //         method: "GET",
    //         headers: {
    //             platform: isMobile.any()
    //                 ? HEADER_CONSTANTS.BINGE_WEB_SMALL
    //                 : HEADER_CONSTANTS.WEB,
    //                 'session-id':ssesionid
    //         },
    //         isAPIPrimary: true,
    //     };
    // },

    
    packListingDataMobile: () => {
        const {userAuthorization,baId, subscriberId,dthStatus} = getParamsAPICall();        
         let url = baId?`${process.env.REACT_APP_API_URL}${ENDPOINT_URL.PACK_LISTING_URL_LOGIN}/${baId}`:`${process.env.REACT_APP_API_URL}${ENDPOINT_URL.PACK_LISTING_URL_WITHOUT_LOGIN}`
       //let url = `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.PACK_LISTING_URL_LOGIN}`
        return {
            url: url,
            method: "GET",
            headers: {
                platform: "WEB",
                subscriberId: subscriberId,
                "x-authenticated-userid": subscriberId,
                authorization: userAuthorization,
                dthStatus:dthStatus,
                locale: "en",
            },
            isAPIPrimary: true,
        };
    },
    
fetchConfig: () => {
    return {
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.CONFIG_URL}`,
        method: 'GET',
        headers: {
            apiVersion: 'v3',
            platform: "WEB",
            appversion: "1.0.0"

        },
        isAPIPrimary: true,
    };
},

getPaymentResponse: (param,auth) => {
    // let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    return {
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.PAYMENT_STATUS}`,
        method: "POST",
        headers: {
            authorization: `bearer ${auth}`,
            platform:"WEB"
        },
        data: param,
        isAPIPrimary: true,
    };

},
subscriberDetails: (rmn,dtoken,auth,deviceId,anonymousId) => {
    return {
        'method': 'GET',
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.SUBSCRIBER_DETAILS}`,
        'headers': {
            'mobileNumber': rmn,
            'deviceId': deviceId,
            'anonymousId': anonymousId,
            'platform': 'BINGE_ANYWHERE',
            'x-authenticated-userid':rmn,
            'deviceType':"WEB",
            'deviceName': 'WEB',
            'authorization': `bearer ${auth}`,
            'deviceToken': dtoken//'6ZLHZOg110k9SDe5Cb2TtEWr9HhKxITl'

        }

    };
},
getProfileDetails: (rmn,dtoken,auth,baid,anonymousId) => {
    return {
        'method': 'GET',
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.PROOFILE_DETAILS}/${baid}`,
        'headers': {
            'mobileNumber': rmn,
            'deviceId': APIPAYLOAD.deviceId,
            'anonymousId': anonymousId,
            'platform': 'BINGE_ANYWHERE',
            'x-authenticated-userid':rmn,
            'deviceType':"WEB",
            'deviceName': 'WEB',
            'authorization': `bearer ${auth}`,
            'deviceToken': dtoken,//'6ZLHZOg110k9SDe5Cb2TtEWr9HhKxITl'
            'profileId':'VR_75d5b32f77274826bbb574aee7b9b8d2',
            'subscriptionType':'FREEMIUM'

        }

    };
},
getCurrentSubscription: (rmn,dtoken,auth,param,anonymousId,deviceId) => {
    console.log("abcd--getCurrentSubscription-",rmn,dtoken,auth,param,anonymousId,deviceId);
    
    return {
        'method': 'POST',
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.CURRENT_SUBSCRIBTION_DETAILS}`,
        'headers': {
            'mobileNumber': rmn,
            'deviceId': deviceId,
            'anonymousId': anonymousId,
            'platform': 'BINGE_ANYWHERE',
            'x-authenticated-userid':rmn,
            'deviceType':"WEB",
            'deviceName': 'WEB',
            'authorization': `bearer ${auth}`,
            'deviceToken': dtoken,//'6ZLHZOg110k9SDe5Cb2TtEWr9HhKxITl'
            // 'profileId':'VR_75d5b32f77274826bbb574aee7b9b8d2',
            'subscriptionType':'FREEMIUM'

        },
        data: param,
        isAPIPrimary: true,

    };
},

LogoutAPP: () => {
    const {userAuthorization,deviceAuthenticateToken,baId, subscriberId,anonymousId,deviceId} = getParamsAPICall(); 
    let url = `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.LOGOUT}/${baId}`
       
    return {
      url: url,
      method: "POST",
      headers: {
        deviceId: deviceId,
        subscriberId: subscriberId,
        "x-authenticated-userid": subscriberId,
        platform: "WEB",
        authorization: userAuthorization,
        deviceToken: deviceAuthenticateToken,
      },
      isAPIPrimary: true,
    };
  },

  cancelSubscription: (payload) => {
    const {userAuthorization,deviceAuthenticateToken,baId, subscriberId,anonymousId,deviceId} = getParamsAPICall(); 
    let userinfo = JSON.parse(getKey(LOCALSTORAGE.EXISTING_USER_INFO))
    return {
        url: `${process.env.REACT_APP_API_URL}${ENDPOINT_URL.CANCEL_SUBSCRIPTION}`,
        method: "POST",
        headers: {
            profileId:userinfo?.profileId,
            anonymousId: anonymousId,
            deviceType:"WEB",
            deviceName: "Web",
            deviceId: deviceId,
            deviceToken: deviceAuthenticateToken,
            platform: "WEB",
        },
        data: payload,
        isAPIPrimary: true,
    };
},
}


