// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();  

   return isAuthenticated ? element : <Navigate to="/login" replace />;
  // return isAuthenticated ? <Navigate to="/" replace /> : element;

};

export default ProtectedRoute;
