import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { DataProvider } from '../Context/DataProvider';
import { PurchaseProvider } from '../Context/PurchaseProvider';
import { ToastContainer } from 'react-toastify';
import Loader from '../Common/Loader';
import ProtectedRoute from '../Auth/ProtectedRoute';
import PublicRoute from '../Auth/PublicRoute ';


const CignalRoute = () => {
    const Plan = lazy(() => import('../Container/Plan/Plan'));
    const RMNLogin = lazy(() => import('../Container/Login/RMNLogin/RMNLogin'));
    const OtpLogin = lazy(() => import('../Container/Login/OTP/otp'));
    const PaymentGateWay = lazy(() => import('../Container/PaymentGateway/payamentGateway'));
    const Tennure = lazy(() => import('../Container/Tennure/tennure'));
    const Summary = lazy(() => import('../Container/Summary/Summary'));
    const PaymentSuccess = lazy(() => import('../Container/PaymentSuccess'));

    return (
        // <Router>
            <DataProvider>
                <PurchaseProvider>
                    <Suspense fallback={<Loader />} >
                        {/* fallback={<Loader />} */}
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/"
                             element={<ProtectedRoute element={<Plan />} />}
                             />
                            <Route path="/login" element={<PublicRoute element={<RMNLogin />} restrictedToLoginFlow={false}/>} />
                            <Route path="/otp" element={<PublicRoute element={<OtpLogin />} restrictedToLoginFlow={true} />} />

                            {/* Protected Routes */}
                            <Route
                                path="/payment"
                                element={<ProtectedRoute element={<PaymentGateWay />} />}
                            />
                            <Route
                                path="/tennure"
                                element={<ProtectedRoute element={<Tennure />} />}
                            />
                            <Route
                                path="/summary"
                                element={<ProtectedRoute element={<Summary />} />}
                            />
                            <Route
                                path="/success/*"
                                element={<ProtectedRoute element={<PaymentSuccess />} />}
                            />
                        </Routes>
                        <ToastContainer
                            theme="colored"
                            autoClose={3000}
                            position={'bottom'}
                            pauseOnHover
                            hideProgressBar={false}

                        />
                    </Suspense>
                </PurchaseProvider>
            </DataProvider>
        // </Router>
    );
}
export default CignalRoute;